import React from "react";
import PinkBanner from "../../assets/svg/pinkBanner";
import Koo from "../../assets/svg/koo.svg";
import Twitter from "../../assets/svg/twitter.svg";
import Youtube from "../../assets/svg/youtube.svg";
import Instagram from "../../assets/svg/instagram.svg";
import Facebook from "../../assets/svg/facebook.svg";
import Linkedin from "../../assets/svg/linkedin.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./footer.css";

const Footer = () => {
  const history = useHistory();
  const openPrivacyPolicy = () => {
    try {
      window.open("/assets/pdf/privacy.pdf", "_blank");
    } catch (error) {
      console.error(error);
    }
  };

  const openEmergencyContacts = () => {
    try {
      window.open("/assets/pdf/Emergency_Contacts.pdf", "_blank");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container-fluid footer-main-container  p-0 bg-nav-footer">
      <PinkBanner />
      <div className="card border-0 border text-white  bg-nav-footer mx-3">
        <div className="row  row-custom-margin">
          <div className="row    ">
            <div className="col">
              <div className="row">
                <div className="footer-text pull-left mb-0">
                  <div className="d-flex">
                    <h6>Our Address</h6>
                  </div>
                  <p className="custom-card-text mb-0">
                    37th National Games Secretariat @ Dr. Shyama Prasad
                    Mukherjee Indoor Stadium, Teleigao, Goa -403001 (Near Goa
                    University)
                  </p>
                </div>
              </div>

              <div className="email-body">
                <p>Email:</p>
                <p>support@nationalgamesindia.com</p>
              </div>
              <p>ACT Helpline No. (24*7) - 18001214787</p>
              <button
                className="emergency-contact"
                onClick={openEmergencyContacts}
              >
                Emergency Contact Numbers
              </button>
              <div className="row mt-4">
                <div className="col">
                  <div className="footer-text">
                    <div className="footer-bottom-converstaion-hide d-flex align-items-center">
                      <h6 className="mb-0 text-lg bold">
                        Join The Conversation #GetSetGoa #NGGoa
                      </h6>
                    </div>
                    <div className="footer-bottom-converstaion-visible d-flex align-items-center">
                      <h6 className="mb-0 text-lg bold">
                        Join The Conversation <br />
                        #GetSetGoa #NGGoa
                      </h6>
                    </div>
                    <div className="footer-img">
                      <img
                        src={Twitter}
                        alt="Twitter"
                        style={{
                          width: "18px",
                          height: "15px",
                          marginTop: "3px",
                        }}
                        onClick={() =>
                          window.open(
                            "https://x.com/nat_games_goa?s=11",
                            "_blank"
                          )
                        }
                      />
                      <img
                        src={Instagram}
                        alt="instagram"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginTop: "5px",
                        }}
                        onClick={() =>
                          window.open(
                            "https://instagram.com/goanationalgames?igshid=MzRlODBiNWFlZA==",
                            "_blank"
                          )
                        }
                      />
                      <img
                        src={Facebook}
                        alt="facebook"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginTop: "5px",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.facebook.com/GoaNationalGames/",
                            "_blank"
                          )
                        }
                      />
                      <img
                        src={Youtube}
                        alt="youtube"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginTop: "5px",
                        }}
                        onClick={() =>
                          window.open(
                            "https://youtube.com/@goanationalgames?si=-CbYy3A2YG-BP2E1",
                            "_blank"
                          )
                        }
                      />
                      <img
                        src={Koo}
                        alt="koo"
                        style={{
                          width: "21px",
                          height: "21px",
                          marginTop: "5px",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.kooapp.com/profile/GoaNationalGames",
                            "_blank"
                          )
                        }
                      />
                      <img
                        src={Linkedin}
                        alt="linkedin"
                        style={{
                          width: "20px",
                          height: "23px",
                          marginTop: "5px",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.linkedin.com/company/goa-national-games/?viewAsMember=true",
                            "_blank"
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer-bottom-hide">
              <div className="col">
                <ul className="custom-list">
                  <li onClick={openPrivacyPolicy}>Privacy Policy</li>
                  <li>Cookie Notice</li>
                  <li>Terms and Conditions</li>
                  <li>Accessibility</li>
                  <li>Corporate</li>
                  <li>Sponsors</li>
                  <li>Brand Protection</li>
                  <li onClick={() => history.push("/privacyPolicy")}>
                    Policies and Procedures
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-bottom-visible">
              <div className="col">
                <ul className="custom-list">
                  <li onClick={openPrivacyPolicy}>Privacy Policy</li>
                  <li>Cookie Notice</li>
                  <li>Terms and Conditions</li>
                  <li>Accessibility</li>
                </ul>
              </div>
              <div className="col">
                <ul className="custom-list">
                  <li>Corporate</li>
                  <li>Sponsors</li>
                  <li>Brand Protection</li>
                  <li onClick={() => history.push("/privacyPolicy")}>
                    Policies and Procedures
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row row-custom-margin">
          <div className="col">
            <div className="footer-copyright" style={{ fontSize: "14px" }}>
              <div>
                Copyright &copy; National Games Organising Committee for the
                37th National Games Goa
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <FooterPatner /> */}
    </div>
  );
};

export default Footer;
