import { FaArrowRightLong } from "react-icons/fa6";
import olympicLogo from "../../../assets/home2/header/olympic-games.png";
import { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { schedule_data } from "../data";
import ktspl from "../../../assets/home2/header/KTSPL-White-One.png";


import "./styles.scss"
const Schedule = ({ setIsMenuOpen }) => {
  const [selectedSportType, setSelectedSportType] = useState("overview");

  return (
    <div className="score-main">
      <div className="sports">
        {schedule_data?.map((item) => {
          return (
            <p
              className={
                selectedSportType === item?.value ? "sports-active-btn" : ""
              }
              onClick={() => setSelectedSportType(item?.value)}
            >
              {item?.heading}
            </p>
          );
        })}
      </div>
      {schedule_data?.map((item) => {
        if (item.value === selectedSportType) {
          return (
            <>
              <div className="sport-description">
                <img src={item?.img} alt="" className={item?.img} />
                <p style={{ fontWeight: "600" }}>{item?.heading}</p>
                <p className="des">{item?.description}</p>
                <div className="learn-more">
                  <Link
                    to={item?.link}
                    onClick={(e) => {
                      setIsMenuOpen(false);
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <p>Learn More</p>
                  </Link>
                  <FaArrowRightLong />
                </div>
              </div>
              {/* subsection to thew right */}
              <div className="sport-list">
                {item?.sideOptions?.map((el, index) => (
                  <div className="each-game" key={index}>
                    <img src={el?.image} alt={el?.name} />
                    <div className="des">
                      <p className="name">{el?.name}</p>
                      <p className="description">{el?.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </>
          );
        }
      })}

<div className="official-part">
          <img src={ktspl} />{" "}
          <p className="official-partner" >Official Technology Partner</p>
        </div>
    </div>
  );
};
export default Schedule;
