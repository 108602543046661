import PageTitle from "components/pageTitle/PageTitle";
import TopClock from "../../components/home2/Body/TopClock/Index";
import styles from "./styles.module.css";

const Banner = ({ text = "Participant Team", children, img, clock = 1 ,pagetitle,subPageTitle}) => {
  return (
    <div style={styles.mainContainer}>
      <div>
      <PageTitle motherMenu={pagetitle} activeMenu={subPageTitle}/>
      <div className={`${styles.bannerContainer}`}>
        <img
          src={img ? img : "/assets/images/teams.png"}
          alt="banner"
          className={`${styles.bannerImage}`}
        />
        {text && (
          <h1
            id="participantTeam"
            className={`${styles.participantTeam}`}
            style={{
              maxWidth: "50%",
            }}
          >
            {text}
          </h1>
        )}
        {children}

        {clock && (
          <div
            // style={{
            //   display: "flex",
            //   justifyContent: "space-around",
            //   marginTop: "-2rem",
            //   zIndex: "100",
            // }}
          >
            {/* <TopClock /> */}
          </div>
        )}
      </div>
      </div>
     
    </div>
  );
};

export default Banner;
