import React, { useState, useRef, useEffect } from "react";
import NavLogo from "./NavLogo";
import { useHistory } from "react-router-dom";
import burgerMenu from "../../../assets/home2/header/Menu.png";
import search from "../../../assets/home2/header/search.png";
import profile from "../../../assets/home2/header/profile-icon.svg";
import LoginUser from "./LoginBtn/index";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./styles.css";
import Scores from "components/SubNavbar/Scores";
import Games from "components/SubNavbar/Games";
import Schedule from "components/SubNavbar/Schedule";
import Teams from "components/SubNavbar/Teams";
import MileStones from "components/SubNavbar/Milestones";
import Latest from "components/SubNavbar/Latest";
import Marque from "./Marque/Index";

// images
import uk1 from "../../../assets/home2/Body/uk1.png";
import uk2 from "../../../assets/home2/Body/uk2.png";
import uk3 from "../../../assets/home2/Body/uk3.png";
import uk4 from "../../../assets/home2/Body/uk4.png";
import uk5 from "../../../assets/home2/header/uk1.png";
const Index = () => {
  const history = useHistory();
  const [openNavbar, setOpenNavbar] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [width, setWidth] = useState(window.innerWidth < 992);
  const wrapperRef = useRef(null);
  const [activeLink, setActiveLink] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992); // state to track mobile screen size
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [toggle, settoggle] = useState(false);
  // console.log("activeLink===>",)
  const subMenuList = [
    {
      submenu: "games",
      list: [
        { label: "Schedule & Results", path: "/schedule/overview" },
        { label: "Records", path: "/games/records" },
        { label: "Reports", path: "/games/reports" },
        { label: "Medal Tally", path: "/games/medals" },
      ],
    },
    {
      submenu: "schedule",
      list: [
        { label: "News", path: "/latest/news" },
        { label: "Photos", path: "/latest/photos" },
        { label: "Videos", path: "/latest/videos" },
      ],
    },
    {
      submenu: "scores",
      list: [
        { label: "Hospitality", path: "/visit/hospitality" },
        { label: "Travel", path: "/visit/travel" },
        { label: "Khel Village", path: "/visit/khel-village" },
        { label: "36 National Games", path: "/visit/36-national-games" },
      ],
    },
    {
      submenu: "teams",
      list: [
        { label: "National Games", path: "/info/about-national-games" },
        // { label: "Moga (Mascot)", path: "/info/about-moga" },
        { label: "About Committees", path: "/info/committees" },
        // { label: "Goa", path: "/info/about-goa" },
        { label: "IOA", path: "/info/about-ioa" },
        // { label: "Desk Notes", path: "/info/about-desk-notes" },
        { label: "Sponsor", path: "/info/about-sponsors" },
        { label: "Contact Us", path: "/info/contact-us" },
      ],
    },
    {
      submenu: "latest",
      list: [
        { label: "News", path: "/latest/news" },
        { label: "Photos", path: "/latest/photos" },
        { label: "Videos", path: "/latest/videos" },
      ],
    },
    {
      submenu: "milestones",
      list: [
        { label: "News", path: "/latest/news" },
        { label: "Photos", path: "/latest/photos" },
        { label: "Videos", path: "/latest/videos" },
      ],
    },
    // {
    //   submenu: "stats",
    //   list: [
    //     { label: "News", path: "/latest/news" },
    //     { label: "Photos", path: "/latest/photos" },
    //     { label: "Videos", path: "/latest/videos" },
    //   ],
    // },
  ];

  const scrollToTop = () => {
    const element = document.getElementById("home-body");
    if (element) {
      element.scrollIntoView({ top: 0, behavior: "smooth" });
    }
  };

  const handleClick = (menu) => {
    if (selectedMenu == menu?.submenu) {
      setIsMenuOpen((b_val) => !b_val);
      return;
    }

    setSelectedMenu(menu?.submenu);
    setIsMenuOpen(true);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  return (
    <div className="navbar-main">
      <Marque />
      <div className="navbar-fields">
        <div className="left-navbar-items">
          <div className="burger-container">
            <img
              onClick={toggleMobileMenu}
              src={burgerMenu}
              alt="logo"
              className="logo hamburgerMenu"
              style={{
                width: "40px",
                marginBottom: "0px",
              }}
            />
          </div>
          <div className="navLogo">
            <NavLogo
              click={() => {
                scrollToTop();
              }}
            />
          </div>
        </div>
        <div className="nav-items-dropdows">
          {subMenuList.map((menu, index) => (
            <div
              key={index}
              onClick={() => {
                setActiveLink(index);
                handleClick(menu);
                settoggle(!toggle);
              }}
            >
              <h6 className={`text-${index === activeLink ? "primary" : null}`}>
                {menu.submenu.toUpperCase()}
                {menu.submenu.toUpperCase() !== "STATS" &&
                  menu.submenu.toUpperCase() !== "SCORES" && (
                    toggle && index === activeLink ? (
                      <i className={`bi bi-chevron-up text-${index === activeLink ? "primary dowen_arrow" : ""}`}></i>
                    ) : (
                      <i className={`bi bi-chevron-down text-${index === activeLink ? "primary" : ""}`}></i>
                    )
                    
                  )}
              </h6>
            </div>
          ))}
        </div>
        <div className="right-navbar-items">
          <div className="menu_search_box">
            <div>
              <i className="bi bi-search"></i>
              <input type="text" placeholder="Search..." />
            </div>
          </div>
          <div className="login-btn-none">
            <LoginUser />
          </div>
        </div>

        <div className="searchBtn">
          <img
            src={profile}
            alt="profile"
            style={{ width: "40px", marginBottom: "0px" }}
          />
        </div>
      </div>

      {/* <div className="logo_wrapper_mobile_container">
        <div className="logo_wrapper_mobile">
          <div>
            <img src={uk3} alt="logo" className="logo" width="25" height="25" />
          </div>
          <div className="mx-2">
            <img src={uk4} alt="logo" className="logo" width="25" height="25" />
          </div>

          <div className="mx-2">
            <img src={uk2} alt="logo" className="logo" width="25" height="25" />
          </div>
          <div className="mx-1">
            <img src={uk5} alt="logo" className="logo" width="25" height="25" />
          </div>
          <div>
            <img src={uk1} alt="logo" className="logo" width="25" height="25" />
          </div>
        </div>
      </div> */}

      {isMenuOpen && !isMobile && (
        <div className="sub-navbar-main">
          {selectedMenu === "scores" && (
            <Scores setIsMenuOpen={setIsMenuOpen} />
          )}
          {selectedMenu === "games" && <Games setIsMenuOpen={setIsMenuOpen} />}
          {selectedMenu === "schedule" && (
            <Schedule setIsMenuOpen={setIsMenuOpen} />
          )}
          {selectedMenu === "teams" && <Teams setIsMenuOpen={setIsMenuOpen} />}
          {selectedMenu === "milestones" && (
            <MileStones setIsMenuOpen={setIsMenuOpen} />
          )}
          {selectedMenu === "latest" && (
            <Latest setIsMenuOpen={setIsMenuOpen} />
          )}
        </div>
      )}

      {isMobile && isMobileMenuOpen && (
        <>
          <div className="mobile-menu">
            {subMenuList.map((menu, index) => (
              <div
                key={index}
                className={`mobile-menu-item ${
                  isMenuOpen && selectedMenu === menu.submenu ? "open" : ""
                }`}
              >
                <h6
                  className={`${index === activeLink ? "list-item" : "p-3"} `}
                  onClick={() => {
                    setActiveLink(index);
                    handleClick(menu);
                    settoggle(!toggle);
                  }}
                >
                  {menu.submenu.toUpperCase()}
                  {menu.submenu.toUpperCase() !== "STATS" &&
                    menu.submenu.toUpperCase() !== "SCORES" &&
                    (toggle && activeLink === index ? (
                      <i className="bi bi-chevron-up px-2"></i>
                    ) : (
                      <i className="bi bi-chevron-down px-2"></i>
                    ))}
                </h6>
                {isMenuOpen && selectedMenu === menu.submenu && (
                  <ul className="mobile-submenu">
                    {menu.list.map((item, itemIndex) => (
                      <li key={itemIndex} className="mobile-submenu-list-item">
                        <a href={item.path}>{item.label}</a>
                        <i class="bi bi-arrow-right text-white"></i>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>

          {/* mapping */}
          {/* <div className="sub-navbar-main">
            {selectedMenu === "scores" && (
              <Scores setIsMenuOpen={setIsMenuOpen} />
            )}
            {selectedMenu === "games" && (
              <Games setIsMenuOpen={setIsMenuOpen} />
            )}
            {selectedMenu === "schedule" && (
              <Schedule setIsMenuOpen={setIsMenuOpen} />
            )}
            {selectedMenu === "teams" && (
              <Teams setIsMenuOpen={setIsMenuOpen} />
            )}
            {selectedMenu === "milestones" && (
              <MileStones setIsMenuOpen={setIsMenuOpen} />
            )}
            {selectedMenu === "latest" && (
              <Latest setIsMenuOpen={setIsMenuOpen} />
            )}
          </div> */}
        </>
      )}
    </div>
  );
};

export default Index;
