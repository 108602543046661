import { Link } from "react-router-dom";
import "./styles.scss";

const Index = () => {
  return (
    <div className="marquee enable-animation">
       <ul className="marquee__content">
        <li>
          <span className="marque-heading">
            {/* Unified Sports Portal Uttarakhand:{" "} */}
            29th August [National Sports Day] :{" "}
          </span>
          {/* <span className="dot">•</span>{" "} */}
          <a href="https://38nguk.in/" className="marquee_title_label">
            {/* Visit the Unified Sports Portal Uttarakhand */}
            Launching the Website of 38th National Games of India, Uttarakhand.
          </a>
        </li>
     
        <li>
          <span className="marque-heading">
            {/* Unified Sports Portal Uttarakhand:{" "} */}
            NEWS :{" "}
          </span>
          {/* <span className="dot">•</span>{" "} */}
          <a
            href="https://38nguk.in/latest/news/1"
            className="marquee_title_label"
          >
            {/* Visit the Unified Sports Portal Uttarakhand */}
            CM Dhami Holds Strategic Meeting on 38th National Games Preparations
          </a>
        </li>
        <li>
          <span className="marque-heading">
            {/* Unified Sports Portal Uttarakhand:{" "} */}
            OLYMPIAN FELICITATED :{" "}
          </span>
          {/* <span className="dot">•</span>{" "} */}
          <a
            href="https://38nguk.in/latest/photos"
            className="marquee_title_label"
          >
            {/* Visit the Unified Sports Portal Uttarakhand */}
            View Photos of Olympians from Uttarakhand
          </a>
        </li>
      </ul>
      <ul className="marquee__content">
        <li>
          <span className="marque-heading">
            {/* Unified Sports Portal Uttarakhand:{" "} */}
            29th August [National Sports Day] :{" "}
          </span>
          {/* <span className="dot">•</span>{" "} */}
          <a href="https://38nguk.in/" className="marquee_title_label">
            {/* Visit the Unified Sports Portal Uttarakhand */}
            Launching the Website of 38th National Games of India, Uttarakhand.
          </a>
        </li>
     
        <li>
          <span className="marque-heading">
            {/* Unified Sports Portal Uttarakhand:{" "} */}
            NEWS :{" "}
          </span>
          {/* <span className="dot">•</span>{" "} */}
          <a
            href="https://38nguk.in/latest/news/1"
            className="marquee_title_label"
          >
            {/* Visit the Unified Sports Portal Uttarakhand */}
            CM Dhami Holds Strategic Meeting on 38th National Games Preparations
          </a>
        </li>
        <li>
          <span className="marque-heading">
            {/* Unified Sports Portal Uttarakhand:{" "} */}
            OLYMPIAN FELICITATED :{" "}
          </span>
          {/* <span className="dot">•</span>{" "} */}
          <a
            href="https://38nguk.in/latest/photos"
            className="marquee_title_label"
          >
            {/* Visit the Unified Sports Portal Uttarakhand */}
            View Photos of Olympians from Uttarakhand
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Index;
