import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./styles.css";

const handleLoginClick = () => {
  window.location.href = "/";
};

const Index = () => {
  return (
    <div className="login-btn" onClick={handleLoginClick}>
      <div>
        <i class="bi bi-person"></i>
      </div>
      <div>
        <span>Login</span>
      </div>
    </div>
  );
};

export default Index;
