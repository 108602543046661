import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';

import { sportToImgIcons } from "./VenueDates";
import greendoticon from '../../assets/home2/Schedule/Ellipse 64.png'
import torch from "../../assets/home2/Schedule/torch 1.png"
import { sportsName } from './Schedulejson';


const datesArray = ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5'];
const getPropertyNoCase = (obj, prop) => {
    return obj[
        Object.keys(obj)?.find((key) =>
            prop?.toLowerCase()?.includes(key.toLowerCase())
        )
    ];
};

const SportSchedule = () => {
    return (
        <Container fluid>
            {sportsName.map((item) => {
                return (
                    <>
                        <Row className=" mb-3">
                            <Col lg={2} className=" text-start">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <img
                                            loading="lazy"
                                            src={
                                                getPropertyNoCase(
                                                    sportToImgIcons,
                                                    item?.key ?? item?.sportName
                                                ) ?? item?.img
                                            }
                                            alt={`Icon for ${item?.sportName}`}
                                            className={`icon  rounded-2 m-2 ${item.sportName === "Opening & Closing Ceremonies" ? "" : "linergradient"}`}
                                            style={{
                                                width: "60px",
                                                height: "60px",
                                                objectFit: "contain",
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <p
                                            style={{
                                                marginBottom: "5px",
                                                fontSize: "20px",
                                                textAlign: "start",
                                            }}
                                        >
                                            {item?.sportName}
                                        </p>
                                    </div>


                                </div>

                            </Col>
                            <Col lg={10} className="d-flex justify-content-center align-items-center">
                                <Row className="w-100 flex-wrap">
                                    {Array.from({ length: 15 }).map((_, index) => (
                                        <Col
                                            key={index}
                                            className="d-flex justify-content-center sport_cube_col"
                                        >

                                            <div className={`${item.id % 2 === 0 ? "sport_cube_box" : "sport_cube_box_white"}`}>
                                               
                                                {index === 0 && item.sportName === "Opening & Closing Ceremonies" ? (
                                                    <img src={torch} alt="Torch Icon" />
                                                ) : (
                                                   
                                                    item.sportName !== "Opening & Closing Ceremonies" && (
                                                        <img src={greendoticon} alt="Green Dot Icon" />
                                                    )
                                                )}
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                    </>
                )
            })}

        </Container>
    );
};

export default SportSchedule;
