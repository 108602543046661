import uttrakhandimg from "../../assets/home2/Body/uttrakhandopen.png"
import cycling from "../../assets/home2/Cluster/cycling.png"

export const sportsName = [
    {
        id: 1,
        sportName: "Opening & Closing Ceremonies",
        date: ["26 October 2023", "09 November 2023"],
        startDate: null,
        endDate: null,
        img: uttrakhandimg,

    },
    {
        id: 2,
        sportName: "Badminton",
        startDate: "19 October 2023",
        endDate: "24 October 2023",
        routing: "badminton",
        scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
          
    },
    {
        id: 3,
        sportName: "Netball",
        startDate: "22 October 2023",
        endDate: "27 October 2023",
        routing: "netball",
        scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 4,
        sportName: "Basketball",
        startDate: "23 October 2023",
        endDate: "28 October 2023",
        routing: "basketball",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 5,
        sportName: "Gymnastics",
        startDate: "23 October 2023",
        endDate: "28 October 2023",
        routing: "gymnasium",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 6,
        sportName: "Rugby",
        startDate: "25 October 2023",
        endDate: "27 October 2023",
        routing: "rugby",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 7,
        sportName: "Weightlifting",
        startDate: "25 October 2023",
        endDate: "29 October 2023",
        routing: "weightlifting",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 8,
        sportName: "Mallakhamb",
        startDate: "26 October 2023",
        endDate: "28 October 2023",
        routing: "mallakhamb",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 9,
        sportName: "Pencak Silat",
        startDate: "26 October 2023",
        endDate: "29 October 2023",
        key: "pencak-silat",
        routing: "pencak-silat",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {

        id: 10,
        sportName: "Modern Pentathlon",
        startDate: "26 October 2023",
        endDate: "29 October 2023",
        routing: "modern-pentathlon",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 11,
        sportName: "Fencing",
        startDate: "26 October 2023",
        endDate: "30 October 2023",
        routing: "fencing",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 12,
        sportName: "Billiards & Snookers",
        startDate: "27 October 2023",
        endDate: "30 October 2023",
        key: "billiard-&-snooker",
        routing: "billiard-&-snooker",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 13,
        sportName: "Football (Women)",
        startDate: "27 October 2023",
        endDate: "5 November 2023",
        routing: "football",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 14,
        sportName: "Rowing",
        startDate: "28 October 2023",
        endDate: "1 November 2023",
        routing: "rowing",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 15,
        sportName: "Beach Football",
        startDate: "28 October 2023",
        endDate: "1 November 2023",
        routing: "beach-football",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 16,
        sportName: "Table Tennis",
        startDate: "29 October 2023",
        endDate: "2 November 2023",
        routing: "table-tennis",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 17,
        sportName: "Athletics",
        startDate: "29 October 2023",
        endDate: "3 November 2023",
        routing: "athletics",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 18,
        sportName: "Aquatics",
        startDate: "29 October 2023",
        endDate: "4 November 2023",
        routing: "aquatics",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 19,
        sportName: "Archery",
        startDate: "29 October 2023",
        endDate: "6 November 2023",
        routing: "archery",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 20,
        sportName: "Rollball",
        startDate: "30 October 2023",
        endDate: "2 November 2023",
        routing: "rollball",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 21,
        sportName: "Sepak Takraw",
        startDate: "30 October 2023",
        endDate: "3 November 2023",
        key: "sepak-takraw",
        routing: "sepak-takraw",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 22,
        sportName: "Lawn Tennis",
        startDate: "30 October 2023",
        endDate: "5 November 2023",
        routing: "lawn-tennis",
    },
    {
        id: 23,
        sportName: "Hockey",
        startDate: "30 October 2023",
        endDate: "8 November 2023",
        routing: "hockey",
    },
    {
        id: 24,
        sportName: "Football (Men)",
        startDate: "30 October 2023",
        endDate: "8 November 2023",
        routing: "football",
    },
    {
        id: 25,
        sportName: "Gatka",
        startDate: "31 October 2023",
        endDate: "2 November 2023",
        routing: "gatka",

    },
    {
        id: 26,
        sportName: "Taekwondo",
        startDate: "31 October 2023",
        endDate: "3 November 2023",
        routing: "taekwondo",
    },
    {
        id: 27,
        sportName: "Mini Golf",
        startDate: "1 November 2023",
        endDate: "3 November 2023",
        routing: "mini-golf",
    },
    {
        id: 28,
        sportName: "Wrestling",
        startDate: "1 November 2023",
        endDate: "3 November 2023",
        routing: "wrestling",
    },
    {
        id: 29,
        sportName: "Wushu",
        startDate: "1 November 2023",
        endDate: "4 November 2023",
        routing: "wushu",
    },
    {
        id: 30,
        sportName: "Volleyball",
        startDate: "1 November 2023",
        endDate: "5 November 2023",
        routing: "volleyball",
    },
    {
        id: 31,
        sportName: "Squash",
        startDate: "1 November 2023",
        endDate: "5 November 2023",
        routing: "squash",
    },
    {
        id: 32,
        sportName: "Lawn Bowls",
        startDate: "1 November 2023",
        endDate: "8 November 2023",
        key: "lawn-bowl",
        routing: "lawn-bowl",
    },
    {
        id: 33,
        sportName: "Boxing",
        startDate: "1 November 2023",
        endDate: "8 November 2023",
        routing: "boxing",
    },
    {
        id: 34,
        sportName: "Cycling (Track Race)",
        startDate: "2 November 2023",
        endDate: "5 November 2023",
        routing: "cycling",
    },
    {
        id: 35,
        sportName: "Shooting",
        startDate: "2 November 2023",
        endDate: "9 November 2023",
        routing: "shooting",
    },
    {
        id: 36,
        sportName: "Rowing (Coastal/Tidal Sport)",
        startDate: "3 November 2023",
        endDate: "6 November 2023",
        routing: "rowing",
    },
    {
        id: 37,
        sportName: "Yachting",
        startDate: "3 November 2023",
        endDate: "8 November 2023",
        routing: "yachting",
    },
    {
        id: 38,
        sportName: "Triathlon",
        startDate: "4 November 2023",
        endDate: "7 November 2023",
        routing: "triathlon",
    },
    {
        id: 40,
        sportName: "Canoeing & Kayaking",
        startDate: "4 November 2023",
        endDate: "7 November 2023",
        routing: "canoeing-&-kayaking",
    },
    {
        id: 41,
        sportName: "Beach Volleyball",
        startDate: "4 November 2023",
        endDate: "7 November 2023",
        routing: "beach-volleyball",
    },
    {
        id: 42,
        sportName: "Handball",
        startDate: "4 November 2023",
        endDate: "8 November 2023",
        routing: "handball",
    },
    {
        id: 43,
        sportName: "Kabaddi",
        startDate: "4 November 2023",
        endDate: "8 November 2023",
        routing: "kabaddi",
    },
    {
        id: 44,
        sportName: "Kho-Kho",
        startDate: "4 November 2023",
        endDate: "8 November 2023",
        routing: "kho-kho",
    },
    {
        id: 45,
        sportName: "Lagori",
        startDate: "5 November 2023",
        endDate: "6 November 2023",
        routing: "lagori",
    },
    {
        id: 46,
        sportName: "Beach Handball",
        startDate: "5 November 2023",
        endDate: "9 November 2023",
        routing: "beach-handball",
    },
    {
        id: 47,
        sportName: "Golf",
        startDate: "5 November 2023",
        endDate: "9 November 2023",
        routing: "golf",
    },
    {
        id: 48,
        sportName: "Yogasana",
        startDate: "5 November 2023",
        endDate: "9 November 2023",
        routing: "yogasana",
    },
    {
        id: 49,
        sportName: "Sqay Martial Arts",
        startDate: "6 November 2023",
        endDate: "8 November 2023",
        key: "sqay-martial-arts",
        routing: "sqay-martial-arts",
    },
    {
        id: 50,
        sportName: "Judo",
        startDate: "6 November 2023",
        endDate: "8 November 2023",
        routing: "judo",
    },
    {
        id: 51,
        sportName: "Kalaripayattu",
        startDate: "7 November 2023",
        endDate: "8 November 2023",
        routing: "kalaripayattu",
    },
    {
        id: 52,
        sportName: "Cycling (Road Race)",
        startDate: "8 November 2023",
        endDate: "9 November 2023",
        routing: "cycling",
    },
];

export const dateData = [
    {
      id: 1,
      date: 25,
      day: "Tue"
    },
    {
      id: 2,
      date: 26,
      day: "Wed"
    },
    {
      id: 3,
      date: 27,
      day: "Thu"
    },
    {
      id: 4,
      date: 28,
      day: "Fri"
    },
    {
      id: 5,
      date: 29,
      day: "Sat"
    },
    {
      id: 6,
      date: 30,
      day: "Sun"
    },
    {
      id: 7,
      date: 31,
      day: "Mon"
    },
    {
      id: 8,
      date: 1,
      day: "Tue"
    },
    {
      id: 9,
      date: 2,
      day: "Wed"
    },
    {
      id: 10,
      date: 3,
      day: "Thu"
    },
    {
      id: 11,
      date: 4,
      day: "Fri"
    },
    {
      id: 12,
      date: 5,
      day: "Sat"
    },
    {
      id: 13,
      date: 6,
      day: "Sun"
    },
    {
      id: 14,
      date: 7,
      day: "Mon"
    },
    {
      id: 15,
      date: 8,
      day: "Tue"
    }
  ];


  export const clusterdatalist = [
    {
        id: 1,
        clusterHeading: "Dehradun",
        date: ["26 October 2023", "09 November 2023"],
        startDate: null,
        endDate: null,
        img: uttrakhandimg,

    },
    {
        id: 2,
        clusterHeading: "Haridwar",
        startDate: "19 October 2023",
        endDate: "24 October 2023",
        routing: "badminton",
        scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
          
    },
    {
        id: 3,
        clusterHeading: "Tehri",
        startDate: "22 October 2023",
        endDate: "27 October 2023",
        routing: "netball",
        scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 4,
        clusterHeading: "Rishikesh",
        startDate: "23 October 2023",
        endDate: "28 October 2023",
        routing: "basketball",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 5,
        clusterHeading: "Rudrapur",
        startDate: "23 October 2023",
        endDate: "28 October 2023",
        routing: "gymnasium",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 6,
        clusterHeading: "Haldwani",
        startDate: "25 October 2023",
        endDate: "27 October 2023",
        routing: "rugby",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 7,
        clusterHeading: "Nainital",
        startDate: "25 October 2023",
        endDate: "29 October 2023",
        routing: "weightlifting",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
   
   
    
   
   
   
   
    
    
    
   
];
  