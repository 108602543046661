export const footer1 = [
    { name: "Home", path: "/" },
    { name: "Schedule and Results", path: "/schedule/overview" },
    { name: "Medal Tally", path: "/games/medals" },
    { name: "Visit", path: "/" },
    { name: "Teams", path: "/teams" },
    { name: "Milestones", path: "/milestone/all" },
    // { name: "Games Culture", path: "/milestone/all" },
  ]

  export const sportsOptions = [
    { name: "Aquatics", path: "/sports/aquatics" },
    { name: "Archery", path: "/sports/archery" },
    { name: "Athletics", path: "/sports/athletics" },
    { name: "Badminton", path: "/sports/badminton" },
    { name: "Basketball", path: "/sports/basketball" },
    { name: "Boxing", path: "/sports/boxing" },
    { name: "Cycling", path: "/sports/cycling" },
    { name: "Equestrian", path: "/sports/equestrian" },
    { name: "Fencing", path: "/sports/fencing" },
    { name: "Football", path: "/sports/football" },
    { name: "Gymnastics", path: "/sports/gymnastics" },
    { name: "Hockey", path: "/" },
    { name: "Handball", path: "/sports/handball" },
    { name: "Judo", path: "/" },
    { name: "Kabaddi", path: "/" },
    { name: "Kayaking", path: "/" },
    
  
  ];

  export const footer3 = [
    { name: "Kho-Kho", path: "/sports/kho-kho" },
    { name: "Karate", path: "/" },
    { name: "Lawn Ball", path: "/" },
    { name: "Modern Pentathlon", path: "" },
    { name: "Mallakhamb", path: "/" },
   
    { name: "Netball", path: "/" },
    { name: "Rowing", path: "/sports/kabaddi" },
    { name: "Rugby", path: "/sports/karate" },
    { name: "Shooting", path: "/" },
    { name: "Squash", path: "/" },
    { name: "Sailing", path: "/" },
    { name: "Sepak Takraw", path: "/" },

    { name: "Table Tennis", path: "/" },
    { name: "Tennis", path: "" },
    { name: "Triathlon", path: "/" },  
    { name: "Taekwondo", path: "/" },
   
 
  ];
  

  export const footer4 = [
    { name: "Volleyball", path: "/" }, 
    { name: "Weightlifting", path: "/" },  
    { name: "Wushu", path: "/" },  
    { name: "Wrestling", path: "/" }, 
    { name: "Yoga", path: "/" },
   

  ];