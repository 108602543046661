import "./chatBotMoga.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import mualiimg from "../../assets/home2/Body/muali.png"

const ChatBotHeader = ({ setOpenChatBox }) => {
  const handleBack = () => {
    setOpenChatBox(false);
  };

  return (
    <div className="chatbot-header">
      <div className="intercom-header">
        <ArrowBackIosNewIcon onClick={() => handleBack()} />
        <img
          loading="lazy"
          src={mualiimg}
          alt="Logo"
          className="homeLogo"
        />
        <div className="intercom-profile">
          <p className="intercom-header-name">Moga</p>
          <p className="mogo-desc">Your Digital Assistant</p>
        </div>
      </div>
    </div>
  );
};

export default ChatBotHeader;
